window.$ = window.jQuery = require('jquery');
// import $ from "jquery";
require('materialize-css/dist/js/materialize');
require('slick-carousel');

$(document).ready(function(){

    $(window).scroll(function() {    
        var scroll = $(window).scrollTop();
        if (scroll >= 50) {
            $(".navbar-fixed").addClass("sticky");
        } else {
            $(".navbar-fixed").removeClass("sticky");
        }
    });

    $(document.body).trigger('wc_fragment_refresh');

    $('.sidenav').sidenav();
    $('.collapsible').collapsible();

    // $('.select').formSelect();
    // $('.select .country_select').formSelect();
    // $('.select .state_select').formSelect();

    $('.banner-slider').slick({
        prevArrow: $('.btn-prev'),
        nextArrow: $('.btn-next'),
        dots: true,
        // autoplay: 300
    });
    
    $('.team-slider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        prevArrow: $('.btn-prev'),
        nextArrow: $('.btn-next'),
        // autoplay: 300
        responsive: [
            {
                breakpoint: 993,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 601,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

});